<template>
  <a-spin :spinning="loading" class="card-msg">
    <div class="card-title">
      <div class="card-img-title">
        <img src="@/assets/icon/icon_e7fbmzzmou9/renwu1.png" style="width:20px;height:auto;margin-right:10px" alt="" />
        <span @click="changeView(1)" :class="radioValue == 1 ? 'title' : 'no-title'"> 公告</span>
        <span class="title-symbol">/</span>
        <span @click="changeView(2)" :class="radioValue == 2 ? 'title' : 'no-title'">消息</span>
      </div>
      <div>
        <a-icon @click="reload" type="reload" />
        <!-- <a-icon @click="goToMsg" type="more" /> -->
        <span @click="goToMsg" class='iconfont icon-xiao21 icon'></span>
      </div>
    </div>
    <div class="card-content msg-content">
      <div v-if="radioValue == 1">
        <a-row v-for="item in msgTableData" :key="item.id" class="msg-text">
          <a-col :span="14">
            <div @click="showAnnouncement(item)" class="text-title">
              {{ item.title }}
            </div>
          </a-col>
          <a-col :span="10">
            <div @click="showAnnouncement(item)" class="text-title">
              <span>{{ ((item.template?item.template.publishUser&&item.template.publishUser.userName:item.sender)||'')}}</span>
              <span style="margin-left:5px">{{ item.sendTime }}</span>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-else>
        <a-row v-for="item in sysTableData" :key="item.id" class="msg-text">
          <a-col :span="14">
            <div @click="showAnnouncement(item)" class="text-title">
              {{ item.title }}
            </div>
          </a-col>
          <a-col :span="10">
            <div @click="showAnnouncement(item)" class="text-title">
              <span>{{ ((item.template?item.template.publishUser&&item.template.publishUser.userName:item.sender)||'') }}</span>
              <span style="margin-left:5px">{{ item.sendTime }}</span>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { getAction } from '@/api/manage'
export default {
  data() {
    return {
      radioValue: 1,
      msgTableData: [],
      loading: false,
      sysTableData: []
    }
  },
  mounted() {
    this.getMsgTableData()
  },
  watch: {
    radioValue() {
      if (this.radioValue == 1) {
        this.getMsgTableData()
      } else {
        this.getSysTableData()
      }
    }
  },
  methods: {
    showAnnouncement(record) {
      this.$emit('showAnnouncement', record)
    },
    goToMsg() {
      if (this.radioValue == '1') {
        this.$router.push({
          path: '/system/userMsg/?&name=公告'
        })
      } else {
        this.$router.push({
          path: '/system/userAnnouncement/?&name=系统消息'
        })
      }
    },
    changeView(value) {
      this.radioValue = value
    },
    reload() {
      if (this.radioValue == 1) {
        this.getMsgTableData()
      } else {
        this.getSysTableData()
      }
    },
    async getSysTableData() {
      this.loading = true
      let params = {
        current: 1,
        size: 12
      }
      const res = await getAction('/sys/sysAnnouncementSend/getMyAnnouncementSend', params)
      if (res.code == 200) {
        this.sysTableData = res.data.records
        this.loading = false
      } else {
        this.$message.error(res.msg)
        this.loading = false
      }
    },
    async getMsgTableData() {
      this.loading = true
      let params = {
        current: 1,
        size: 12
      }
      const res = await getAction('/sys/sysAnnouncementSend/getMyAnnouncementSend?msgCategory=1', params)
      if (res.code == 200) {
        this.msgTableData = res.data.records
        this.loading = false
      } else {
        this.$message.error(res.msg)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@assets/less/homeStyle.less';
.no-title {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.msg-text {
  &:hover {
    color: var(--theme-color);
  }
}
.text-title {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.ant-row {
  padding-bottom: 5px;
}
.msg-content {
  position: relative;
  top: -4px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { staticClass: "card-msg", attrs: { spinning: _vm.loading } },
    [
      _c("div", { staticClass: "card-title" }, [
        _c("div", { staticClass: "card-img-title" }, [
          _c("img", {
            staticStyle: {
              width: "20px",
              height: "auto",
              "margin-right": "10px",
            },
            attrs: {
              src: require("@/assets/icon/icon_e7fbmzzmou9/renwu1.png"),
              alt: "",
            },
          }),
          _c(
            "span",
            {
              class: _vm.radioValue == 1 ? "title" : "no-title",
              on: {
                click: function ($event) {
                  return _vm.changeView(1)
                },
              },
            },
            [_vm._v(" 公告")]
          ),
          _c("span", { staticClass: "title-symbol" }, [_vm._v("/")]),
          _c(
            "span",
            {
              class: _vm.radioValue == 2 ? "title" : "no-title",
              on: {
                click: function ($event) {
                  return _vm.changeView(2)
                },
              },
            },
            [_vm._v("消息")]
          ),
        ]),
        _c(
          "div",
          [
            _c("a-icon", {
              attrs: { type: "reload" },
              on: { click: _vm.reload },
            }),
            _c("span", {
              staticClass: "iconfont icon-xiao21 icon",
              on: { click: _vm.goToMsg },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "card-content msg-content" }, [
        _vm.radioValue == 1
          ? _c(
              "div",
              _vm._l(_vm.msgTableData, function (item) {
                return _c(
                  "a-row",
                  { key: item.id, staticClass: "msg-text" },
                  [
                    _c("a-col", { attrs: { span: 14 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "text-title",
                          on: {
                            click: function ($event) {
                              return _vm.showAnnouncement(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                    _c("a-col", { attrs: { span: 10 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "text-title",
                          on: {
                            click: function ($event) {
                              return _vm.showAnnouncement(item)
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                (item.template
                                  ? item.template.publishUser &&
                                    item.template.publishUser.userName
                                  : item.sender) || ""
                              )
                            ),
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "5px" } },
                            [_vm._v(_vm._s(item.sendTime))]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              }),
              1
            )
          : _c(
              "div",
              _vm._l(_vm.sysTableData, function (item) {
                return _c(
                  "a-row",
                  { key: item.id, staticClass: "msg-text" },
                  [
                    _c("a-col", { attrs: { span: 14 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "text-title",
                          on: {
                            click: function ($event) {
                              return _vm.showAnnouncement(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                    _c("a-col", { attrs: { span: 10 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "text-title",
                          on: {
                            click: function ($event) {
                              return _vm.showAnnouncement(item)
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                (item.template
                                  ? item.template.publishUser &&
                                    item.template.publishUser.userName
                                  : item.sender) || ""
                              )
                            ),
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "5px" } },
                            [_vm._v(_vm._s(item.sendTime))]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              }),
              1
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }